/* eslint-disable react/prop-types */
import React from 'react';

import Typo from '@dolstaff/shared/es/Typo';
import styled, { css } from 'styled-components';

import { Section } from '../Blocks';

export const BigSection = styled(Section)`
  margin-top: 4em;
  margin-bottom: 4em;

  ${({ selfish }) =>
    selfish &&
    css`
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 3em;
      padding-bottom: 3em;
    `}

  @media screen and (min-width: 1024px) {
    margin-top: 8em;
    margin-bottom: 8em;

    ${({ selfish }) =>
      selfish &&
      css`
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 6em;
        padding-bottom: 6em;
      `}
  }
`;

export const BigTitle = styled.h1`
  color: #2d3742;
  font-size: 36px;
  line-height: 1.2;
  margin: 1em 0;

  b,
  strong,
  em,
  .em {
    color: #d14242;
  }

  @media screen and (min-width: 1024px) {
    font-size: 48px;
  }
`;

export const Panel = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;

  b,
  strong,
  .em {
    color: #d14242;
  }

  .panelLeft {
    margin-bottom: 2em;
  }
  .panelRight {
  }

  @media screen and (min-width: 1024px) {
    display: flex;

    .panelLeft {
      flex: 40% 0 0;
      margin-right: 2em;
      margin-bottom: 0;
    }
    .panelRight {
      flex: auto;
      margin-left: 2em;
    }
  }
`;

// on mobile, panelImage will be displayed underneath the .panelBody
export const ImagePanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  margin-bottom: 2em;

  .panelImage {
  }
  .panelBody {
    margin-bottom: 1em;
  }

  .title {
    font-size: 32px;
    color: #d14242;
    margin: 0.5em 0;
    line-height: 1.2;
  }
  .content {
    font-size: 18px;
    color: #2d3742;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 4em;
    margin-bottom: 4em;

    .panelImage {
      flex: 500px 0 0;
    }
    .panelBody {
      flex: auto;
      margin-right: 6em;
    }

    &.reverse {
      flex-direction: row-reverse;

      .panelBody {
        margin-left: 0;
        margin-left: 6em;
      }
    }
  }
`;

// on mobile, .panelImage will be displayed above the .panelBody
export const ImageTopPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  margin-bottom: 2em;

  .panelImage {
    margin-bottom: 1em;
  }
  .panelBody {
  }

  .title {
    font-size: 32px;
    color: #d14242;
    margin: 0.5em 0;
    line-height: 1.2;
  }
  .content {
    font-size: 18px;
    color: #2d3742;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 4em;
    margin-bottom: 4em;

    .panelImage {
      flex: 500px 0 0;
    }
    .panelBody {
      flex: auto;
      margin-left: 6em;
    }

    &.reverse {
      flex-direction: row-reverse;

      .panelBody {
        margin-left: 0;
        margin-right: 6em;
      }
    }
  }
`;

export const BigLabel = styled.div`
  background-color: #f05d23;
  border-radius: 4px;
  font-size: 18px;
  line-height: 32px;
  padding: 6px 1em;
  display: inline-block;
  color: white;
  margin-bottom: 1em;
`;

export const NavButtonComp = styled.div`
  box-shadow: 0px 4px 4px rgba(209, 66, 66, 0.1);
  height: 48px;
  color: #d14242;
  display: inline-block;
  padding-left: 1em;
  padding-right: 1em;
  min-width: 120px;
  cursor: pointer;
  margin-bottom: 1em;
  transition: all 0.3s;
  margin-right: 1em;

  .navButtonContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active {
    background: #d14242;
    color: white;
  }
`;

export const NavButton = ({ children, ...props }) => {
  return (
    <NavButtonComp {...props}>
      <div className="navButtonContent">{children}</div>
    </NavButtonComp>
  );
};

export const NormalText = styled(Typo).attrs({ fontSize: 18 })`
  color: #535b64;

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .em {
    color: #d14242;
    margin: 0 5px;
  }
  em {
    font-style: normal;
  }

  em,
  strong,
  b {
    color: #d14242;
  }
`;

export const DesktopOnly = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;
