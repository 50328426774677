import { GRAPH_CMS_CDN } from '../../constant';
import GraphCMSImage from 'graphcms-image';
import PropTypes from 'prop-types';
import React from 'react';

const Image = ({
  alt,
  image,
  maxWidth,
  backgroundColor,
  transforms,
  ...props
}) => {
  return (
    <GraphCMSImage
      image={image}
      maxWidth={maxWidth}
      baseURI={GRAPH_CMS_CDN}
      backgroundColor={backgroundColor}
      transforms={transforms}
      alt={alt || 'cms-image'}
      // this will create a new request to download a thumbnail img
      // it's crazy, so I just ignore this feature
      blurryPlaceholder={false}
      fadeIn={false}
      {...props}
    />
  );
};

Image.propTypes = {
  image: PropTypes.shape({}).isRequired,
  backgroundColor: PropTypes.string,
  maxWidth: PropTypes.number,
  transforms: PropTypes.arrayOf(PropTypes.string)
};

Image.defaultProps = {
  maxWidth: 1140,
  backgroundColor: 'whitesmoke',
  transforms: ['quality=value:100']
};

export default Image;
