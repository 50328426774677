import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MyButton from './index';

const WhiteButtonComp = styled(MyButton)`
  font-size: 18px;
  font-weight: 500;
  color: #2d3742;
  line-height: 40px;
  background-color: white;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 0;
  position: relative;
  border: 1px solid #ebecf0;
  overflow: hidden;

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 40px;
    display: block;
    background-color: #2d3742;
    position: absolute;
    transition: all 0.3s;
    transform: translateY(100%);
    z-index: 0;
  }

  .text {
    position: relative;
  }

  &:focus,
  &:active {
    color: #2d3742;
    background-color: white;
    border-color: #242c34;
  }

  &:hover {
    color: white;
    background-color: transparent;
    border-color: #2d3742;

    &:before {
      transform: translateY(0);
    }
  }

  &:disabled {
    color: #b3bac5;
    background-color: white;
    border: 1px solid #ebecf0;
  }
`;

const WhiteButton = ({ children, ...props }) => {
  return (
    <WhiteButtonComp {...props}>
      <span className="text">{children}</span>
    </WhiteButtonComp>
  );
};

WhiteButton.propTypes = {
  children: PropTypes.node
};

export default WhiteButton;
