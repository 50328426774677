import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Image from '../../../Image';
import Title from '../../../MainLayout/Title';
import { ImageTopPanel } from '../../Shared';
import WhiteButton from '../../../MyButton/WhiteButton';
import { SITE_URL } from '../../../../apis/baseURL';
import MyButton from '../../../MyButton';

const Main = styled.div`
  min-height: 320px;

  .mainBody {
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      width: 100%;
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;

    .mainBody {
      margin-right: 2em;
      margin-bottom: 0;
    }
  }
`;

const InputItem = styled.div`
  margin-bottom: 0.5em;
  p {
    font-size: 16px;
    line-height: 24px;
    color: #505f79;
  }
  & .label {
    color: black;
  }
`;

const MsgTitle = styled(Title)`
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #2d3742;
`;

export const CustomWhiteBtn = styled(WhiteButton)`
  height: 48px;
  line-height: 48px;
  ::before {
    height: 48px;
  }
`;
export const CustomRedBtn = styled(MyButton)`
  height: 48px;
  line-height: 48px;
  ::before {
    height: 48px;
  }
  background: #d14242;
  color: #ffffff;
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
`;

const DolRegisterMsg = props => {
  const { bannerImg, topMsg, title, isShowButton } = props;
  const body = (
    <Main>
      <div className="mainBody" style={{ width: '100%' }}>
        <div className="top">
          <div style={{ marginBottom: '46px' }}>
            <MsgTitle>{title}</MsgTitle>
          </div>
          <InputItem>{topMsg}</InputItem>

          {isShowButton && (
            <InputItem style={{ marginTop: '46px' }}>
              <CustomWhiteBtn
                size="large"
                style={{ width: '100%' }}
                type="submit"
                href={SITE_URL}
              >
                Về trang chủ
              </CustomWhiteBtn>
            </InputItem>
          )}
        </div>
      </div>
    </Main>
  );

  if (bannerImg) {
    return (
      <ImageTopPanel className="reverse">
        <div className="panelImage">
          <Image image={bannerImg} maxWidth={500} />
        </div>
        <div className="panelBody">{body}</div>
      </ImageTopPanel>
    );
  }

  return body;
};

DolRegisterMsg.propTypes = {
  bannerImg: PropTypes.string,
  topMsg: PropTypes.node,
  title: PropTypes.string.isRequired,
  isShowButton: PropTypes.bool
};

DolRegisterMsg.defaultProps = {
  isShowButton: true
};

export default DolRegisterMsg;
