import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import DolRegisterMsg from './index';

const Name = styled.span`
  font-weight: 600;
`;

const SuccessMsg = props => {
  const { name } = props;

  if (name) {
    return (
      <>
        <p>
          Cảm ơn <Name>{name}</Name> đã tin tưởng DOL
        </p>
        <p>DOL sẽ liên hệ với bạn sớm nhất có thể</p>
        <p>Nhớ để ý tin nhắn hoặc điện thoại nha</p>
      </>
    );
  }

  return (
    <>
      <p>Cảm ơn bạn đã tin tưởng DOL</p>
      <p>DOL sẽ liên hệ với bạn sớm nhất có thể</p>
      <p>Nhớ để ý tin nhắn hoặc điện thoại nha</p>
    </>
  );
};

SuccessMsg.propTypes = {
  name: PropTypes.string
};

const DolRegisterSuccess = props => {
  const { bannerImg, name, isShowButton } = props;
  return (
    <DolRegisterMsg
      // title="Bạn đã đăng ký nhận tư vấn"
      bannerImg={bannerImg}
      topMsg={<SuccessMsg name={name} />}
      isShowButton={isShowButton}
    />
  );
};

DolRegisterSuccess.propTypes = {
  bannerImg: PropTypes.string,
  name: PropTypes.string,
  isShowButton: PropTypes.bool
};

DolRegisterSuccess.defaultProps = {
  isShowButton: true
};

export default DolRegisterSuccess;
