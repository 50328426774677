import React from 'react';

import MainLayout from '../shared/MainLayout';
import AfterRegistrationLayout from '../shared/Marketing/Online/DolRegisterMsg/AfterRegistrationLayout';
import DolRegisterSuccess from '../shared/Marketing/Online/DolRegisterMsg/DolRegisterSuccess';
import MySEO from '../shared/MySEO';

class RegisterPage extends React.PureComponent {
  render() {
    return (
      <MainLayout hideSideCTA showFooterRegister={false}>
        <MySEO />
        {/* <DolRegister done /> */}
        <AfterRegistrationLayout>
          <DolRegisterSuccess />
        </AfterRegistrationLayout>
      </MainLayout>
    );
  }
}

RegisterPage.propTypes = {};

export default RegisterPage;
