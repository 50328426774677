import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import cl from 'classnames';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import Image from '../../../Image';
import { Container, Section } from '../../../Blocks';

const kenburnsBottomRight = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translateY(-100%);
  }
`;

const Root = styled(Section).attrs({ selfish: true })`
  background-color: #fbfbfb;
  overflow: hidden;
  position: relative;

  .backgroundWrp {
    min-height: 100%;
    width: 100%;
    min-width: 800px;
    max-height: 320px;
    overflow: hidden;
    margin-top: -2em;
    margin-bottom: 2em;

    .imgWrp {
      width: 100%;
      animation: ${kenburnsBottomRight} 24s linear both infinite;
    }
  }

  .gradientWrp {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 6em;
    padding-bottom: 6em;

    .backgroundWrp {
      position: absolute;
      min-width: 800px;
      left: 40%;
      margin-top: -6em;
      margin-bottom: -6em;

      .imgWrp {
        animation: ${kenburnsBottomRight} 24s linear both infinite;
        &:nth-child(1) {
          display: block;
        }
      }
    }
    .gradientWrp {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgb(251, 251, 251);
      background: linear-gradient(
        110deg,
        rgba(251, 251, 251, 1) 0%,
        rgba(251, 251, 251, 1) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    .gradientWrp.white {
      background: white;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  @media screen and (min-width: 1140px) {
    .backgroundWrp {
      .imgWrp {
        min-width: 120%;
      }
    }
  }
`;

const LeftContent = styled.div`
  flex: 4;
  max-width: 600px;
  @media screen and (max-width: 1140px) {
    margin: auto;
  }
`;
const AfterRegistrationLayout = props => {
  const { backgroundImg, theme } = props;
  return (
    <StaticQuery
      query={graphql`
        query {
          registerImg: file(relativePath: { eq: "index/bg-register.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2430, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
    >
      {data => (
        <Root {...props}>
          <div
            style={{ marginTop: '-8em', paddingTop: '8em' }}
            id="section-register"
          />
          <div className="backgroundWrp">
            <div className="imgWrp">
              {backgroundImg ? (
                <Image
                  image={backgroundImg}
                  maxWidth={2430}
                  alt="backgroundImg"
                />
              ) : (
                <Img
                  fluid={data.registerImg.childImageSharp.fluid}
                  alt="backgroundImg"
                />
              )}
            </div>
            <div className="imgWrp">
              {backgroundImg ? (
                <Image
                  image={backgroundImg}
                  maxWidth={2430}
                  alt="backgroundImg"
                />
              ) : (
                <Img
                  fluid={data.registerImg.childImageSharp.fluid}
                  alt="childImageSharp"
                />
              )}
            </div>
          </div>

          <div className={cl('gradientWrp', theme)} />
          <Container>
            <LeftContent>{props.children}</LeftContent>
          </Container>
        </Root>
      )}
    </StaticQuery>
  );
};

AfterRegistrationLayout.propTypes = {
  theme: PropTypes.string.isRequired,
  done: PropTypes.bool,
  backgroundImg: PropTypes.shape({}),
  children: PropTypes.node
};

export default AfterRegistrationLayout;
